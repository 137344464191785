import React from 'react';
import { Box, Grid, Link, Paper, Typography } from '@material-ui/core';
import screen from '../../hoc/screen';
import ConnectedLayout from '../layouts/ConnectedLayout';
import { DataGrid } from '@material-ui/data-grid';
import { useFetch } from '../../hooks';
import { prix } from '../../utils';
import { Link as RouterLink } from 'react-router-dom';

export const AccueilScreen = screen(
  (theme) => ({
    paper: {
      height: '100%',
    },
    link: {
      color: theme.palette.secondary.main,
    },
  }),
  ({ classes }) => {
    const { data, status } = useFetch('/produits-commandes/stats');
    const { data: statsMagasins } = useFetch('/produits-commandes/magasins');
    const { data: statsFournisseurs } = useFetch(
      '/produits-commandes/fournisseurs'
    );

    return (
      <ConnectedLayout title={'Tableau de bord'}>
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className={classes.paper}>
                <Box align={'center'} p={3}>
                  <Box mb={3}>
                    <Typography variant={'h6'}>
                      Chiffre d'affaire Total
                    </Typography>
                  </Box>
                  <Typography variant={'h4'} component={'div'}>
                    {status === 'fetching' ? '...' : prix(data.ca)}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className={classes.paper}>
                <Box align={'center'} p={3}>
                  <Box mb={3}>
                    <Typography variant={'h6'}>Nombre de commandes</Typography>
                  </Box>
                  <Typography variant={'h4'} component={'div'}>
                    {status === 'fetching' ? '...' : data.nbCommandes}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Paper style={{ height: '100%' }}>
                <Box align={'center'} p={3}>
                  <Box mb={3}>
                    <Typography variant={'h6'}>Top ventes</Typography>
                  </Box>
                  <Box align={'left'}>
                    {status === 'fetching'
                      ? '...'
                      : (
                          (data &&
                            data.topVentes &&
                            Object.keys(data.topVentes)) ||
                          []
                        ).map((produitId, i) => (
                          <Typography
                            key={produitId}
                            variant={'body1'}
                            component={'div'}
                            noWrap
                          >
                            <Link
                              href={`https://offres-salon-espace-emeraude.fr/produit/${produitId}`}
                              target={'_blank'}
                              color={'secondary'}
                            >
                              {++i}. {data.topVentes[produitId].nom} (
                              {data.topVentes[produitId].nbVentes})
                            </Link>
                          </Typography>
                        ))}
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Paper style={{ height: '100%' }}>
                <Box align={'center'} p={3}>
                  <Box mb={3}>
                    <Typography variant={'h6'}>
                      Statistiques par magasins
                    </Typography>
                  </Box>
                  <Box align={'left'} style={{ height: 500, width: '100%' }}>
                    <DataGrid
                      sortModel={[{ field: 'ca', sort: 'desc' }]}
                      rows={
                        statsMagasins.map((stat) => {
                          stat.id = stat.magasin;
                          return stat;
                        }) || []
                      }
                      columns={[
                        {
                          field: 'magasinNom',
                          headerName: 'Magasin',
                          flex: 1,
                          // renderCell: (params) => (
                          //   <RouterLink
                          //     className={classes.link}
                          //     to={`/commandes/magasins/${params.getValue('id')}`}
                          //   >
                          //     {params.value}
                          //   </RouterLink>
                          // ),
                        },
                        {
                          field: 'ca',
                          type: 'number',
                          headerName: 'C.A.',
                          width: 150,
                          valueFormatter: (params) => prix(params.value),
                        },
                        {
                          field: 'nbVentes',
                          type: 'number',
                          width: 110,
                          headerName: 'Ventes',
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper style={{ height: '100%' }}>
                <Box align={'center'} p={3}>
                  <Box mb={3}>
                    <Typography variant={'h6'}>
                      Statistiques par fournisseurs
                    </Typography>
                  </Box>
                  <Box align={'left'} style={{ height: 500, width: '100%' }}>
                    <DataGrid
                      sortModel={[{ field: 'ca', sort: 'desc' }]}
                      rows={
                        statsFournisseurs.map((stat) => {
                          stat.id = stat.fournisseur;
                          return stat;
                        }) || []
                      }
                      columns={[
                        {
                          field: 'fournisseur',
                          headerName: 'Fournisseur',
                          flex: 1,
                        },
                        {
                          field: 'ca',
                          type: 'number',
                          headerName: 'C.A.',
                          width: 150,
                          valueFormatter: (params) => prix(params.value),
                        },
                        {
                          field: 'nbVentes',
                          type: 'number',
                          width: 110,
                          headerName: 'Ventes',
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </ConnectedLayout>
    );
  }
);

export default AccueilScreen;
