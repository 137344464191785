import { useEffect, useReducer } from 'react';
import { base } from '../config';
import { getStoredUser, useAuth } from '../hoc';

export const useFetch = (url) => {
  const initialState = {
    status: 'idle',
    error: null,
    data: [],
  };
  const { logout } = useAuth();

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' };
      case 'FETCHED':
        return { ...initialState, status: 'fetched', data: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  const fetchData = async (cancelRequest) => {
    dispatch({ type: 'FETCHING' });
    try {
      let headers = {};
      const user = getStoredUser();
      if (user) {
        headers.Authorization = `Bearer ${user.token}`;
      }
      const response = await fetch(`${base}/admin${url}`, { headers });
      const data = await response.json();
      if (data.status === 403) {
        await logout({
          errorMessage:
            'Votre session a expiré, merci de vous authentifier à nouveau.',
        });
      }
      if (cancelRequest) return;
      dispatch({ type: 'FETCHED', payload: data });
    } catch (error) {
      if (cancelRequest) return;
      dispatch({ type: 'FETCH_ERROR', payload: error.message });
    }
  };

  useEffect(() => {
    (async () => {
      let cancelRequest = false;
      if (!url) return;

      await fetchData(cancelRequest);

      return function cleanup() {
        cancelRequest = true;
      };
    })();
  }, [url]);

  return { ...state, refresh: fetchData };
};
