import React from 'react';
import screen from '../../hoc/screen';
import ConnectedLayout from '../layouts/ConnectedLayout';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useFetch } from '../../hooks';
import {DataGridToolbar} from "../atoms/DataGridToolbar";

export const TopVentesFamillesScreen = screen(
  (theme) => ({
    root: {},
    link: {
      color: theme.palette.secondary.main,
    },
  }),
  () => {
    const { data } = useFetch('/familles/top');
    return (
      <ConnectedLayout title={'Top des ventes par familles'}>
        <Typography variant={'h4'} component={'h1'}>
          Top des ventes par familles
        </Typography>
        <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
          Liste des 3 meilleures ventes par familles de produit.
        </Typography>
        <Paper>
          <Box mt={3} p={2}>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Box height={900}>
                  <DataGrid
                    localeText={{toolbarExportCSV: 'Télécharger au format CSV'}}
                    components={{
                      Toolbar: DataGridToolbar,
                    }}
                    sortModel={[{ field: 'nom', sort: 'asc' }]}
                    rows={data || []}
                    rowsPerPageOptions={[25, 50, 100, 200]}
                    columns={[
                      {
                        field: 'nom',
                        headerName: 'Famille',
                        flex: 1,
                      },
                      {
                        field: 'premier',
                        headerName: 'Premier produit',
                        flex: 1,
                      },
                      {
                        field: 'second',
                        headerName: 'Second produit',
                        flex: 1,
                      },
                      {
                        field: 'troisieme',
                        headerName: 'Troisième produit',
                        flex: 1,
                      },
                    ]}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </ConnectedLayout>
    );
  }
);

export default TopVentesFamillesScreen;
