import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import screen from '../../hoc/screen';
import ConnectedLayout from '../layouts/ConnectedLayout';
import { Box, Grid, Paper, Typography, Link, Button } from '@material-ui/core';
import { useFetch } from '../../hooks';
import { prix } from '../../utils';
import { useParams } from 'react-router-dom';
import { ChevronLeft } from '@material-ui/icons';
import {base} from "../../config";
import {getStoredUser} from "../../hoc";

export const CommandeMagasinScreen = screen(
  (theme) => ({
    root: {},
    link: {
      color: theme.palette.secondary.main,
    },
    btn: {
      margin: theme.spacing(1),
    }
  }),
  ({ classes, history }) => {
    const { id } = useParams();
    const { data } = useFetch(`/magasins/${id}/produits-commandes`);

    const handleExportWithComponent = async () => {
      const user = getStoredUser();
      const headers = {
        Authorization: `Bearer ${user.token}`,
      };
      const endpoint = `${base}/magasins/${id}/produits-commandes/pdf`;
      const res = await fetch(endpoint, {
        headers,
      });
      const fileURL = URL.createObjectURL(await res.blob());
      window.open(fileURL);
    };

    return (
      <ConnectedLayout title={"Commande d'un magasin"}>
        <Typography variant={'h4'} component={'h1'}>
          Commande du magasin :{' '}
          {(data && data[0] && data[0].magasinNom) || '...'}
        </Typography>
        <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
          Liste des produits commandés par un magasin.
        </Typography>
        <Box py={2} align={'right'}>
          <Button
            className={classes.btn}
            startIcon={<ChevronLeft />}
            onClick={() => history.push('/commandes/magasins')}
            color={'primary'}
            variant={'contained'}
          >
            Retour à la liste des commandes
          </Button>
          <Button
            className={classes.btn}
            color={'primary'}
            variant={'contained'}
            title="Exporter le tableau des précommandes"
            onClick={handleExportWithComponent}
          >
            Exporter la précommande
          </Button>
        </Box>
        <Paper>
          <Box mt={3} p={2}>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Box height={900}>
                  <DataGrid
                    sortModel={[{ field: 'total', sort: 'desc' }]}
                    rows={
                      data.map((row) => {
                        row.id = row.produit;
                        return row;
                      }) || []
                    }
                    columns={[
                      {
                        field: 'produitNom',
                        headerName: 'Produit',
                        flex: 1,
                        renderCell: (params) => (
                          <Link
                            className={classes.link}
                            target={'_blank'}
                            href={`https://offres-salon-espace-emeraude.fr/produit/${params.row.id}`}
                          >
                            {params.value}
                          </Link>
                        ),
                      },
                      {
                        field: 'produitMarque',
                        headerName: 'Marque',
                        width: 200,
                      },
                      {
                        field: 'fournisseurNom',
                        headerName: 'Fournisseur',
                        width: 200,
                      },
                      {
                        field: 'prixUnitaire',
                        type: 'number',
                        width: 150,
                        valueFormatter: (params) => prix(params.value),
                        headerName: 'Prix Unitaire',
                      },
                      {
                        field: 'quantite',
                        type: 'number',
                        width: 110,
                        headerName: 'Quantité',
                      },
                      {
                        field: 'total',
                        type: 'number',
                        headerName: 'Total',
                        width: 150,
                        valueFormatter: (params) => prix(params.value),
                      },
                    ]}
                  ></DataGrid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </ConnectedLayout>
    );
  }
);

export default CommandeMagasinScreen;
