import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { context } from '../../hoc';

export const PrivateRoute = context((props) => {
  const { component: Component, ...rest } = props;
  return props.user ? (
    <Route {...rest} component={Component} />
  ) : (
    <Redirect to="/connexion" />
  );
});

export default PrivateRoute;
