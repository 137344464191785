import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Divider,
  List,
  Container,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import screen from '../../hoc/screen';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  LocalShipping,
  ShoppingCart,
  TrendingUp,
} from '@material-ui/icons';

const drawerWidth = 240;

export const ConnectedLayout = screen(
  (theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    listItemIcon: {
      color: theme.palette.secondary.contrastText,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }),
  ({ classes, title, history, children }) => {
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const handleDrawerClose = () => {
      setOpen(false);
    };

    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {title}
            </Typography>
            <IconButton
              onClick={() => history.push('/deconnexion')}
              color="inherit"
            >
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <Typography variant={'h6'}>Espace Emeraude</Typography>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon className={classes.listItemIcon} />
            </IconButton>
          </div>
          <Divider />

          <List>
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText
                primary="Tableau de bord"
                onClick={() => history.push('/accueil')}
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <ShoppingCart className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText
                primary="Magasins"
                onClick={() => history.push('/commandes/magasins')}
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <LocalShipping className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText
                primary="Fournisseurs"
                onClick={() => history.push('/commandes/fournisseurs')}
              />
            </ListItem>
            {/* <ListItem button>
              <ListItemIcon>
                <TrendingUp className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText
                primary="Familles"
                onClick={() => history.push('/familles/top-ventes')}
              />
            </ListItem> */}
            <ListItem button>
              <ListItemIcon>
                <TrendingUp className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText
                primary="Rayons"
                onClick={() => history.push('/rayons/top-ventes')}
              />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {children}
          </Container>
        </main>
      </div>
    );
  }
);

export default ConnectedLayout;
