import React from 'react';
import { Redirect } from 'react-router-dom';
import { context } from '../../hoc';

export const DeconnexionScreen = context(({ logout }) => {
  logout({});
  return <Redirect to={'/connexion'} />;
});

export default DeconnexionScreen;
