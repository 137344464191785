import React from 'react';
import { Avatar, Grid, Typography, Paper } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import spash from '../../assets/login.jpg';
import { ConnexionForm } from '../';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { connexionFormSchema } from '../../configs/schemas-forms';
import screen from '../../hoc/screen';

export const ConnexionScreen = screen(
  (theme) => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `url(${spash})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  }),
  ({ classes, login, history }) => {
    const { handleSubmit, register, errors, setError } = useForm({
      resolver: yupResolver(connexionFormSchema),
    });
    const onSubmit = async (data) => {
      if (await login(data.email, data.motDePasse)) {
        history.push('/');
      }
      setError('email', { message: 'Veuillez vérifier votre adresse email' });
      setError('motDePasse', {
        message: 'Veuillez vérifier votre mot de passe',
      });
    };
    return (
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Connexion à l'espace privé de gestion
            </Typography>
            <ConnexionForm
              onSubmit={handleSubmit(onSubmit)}
              register={register}
              errors={errors}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
);

export default ConnexionScreen;
