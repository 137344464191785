import { Button, TextField, withStyles } from '@material-ui/core';
import React from 'react';

export const ConnexionForm = withStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))(({ classes, onSubmit, register, errors }) => (
  <form className={classes.form} onSubmit={onSubmit} noValidate>
    <TextField
      inputRef={register({ required: true })}
      type={'email'}
      color={'secondary'}
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="email"
      label="Adresse email"
      name="email"
      autoComplete="email"
      autoFocus
      error={!!errors.email}
      helperText={errors?.email?.message}
    />
    <TextField
      inputRef={register({ required: true })}
      color={'secondary'}
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="motDePasse"
      label="Mot de passe"
      type="password"
      id="password"
      autoComplete="current-password"
      error={!!errors.motDePasse}
      helperText={errors?.motDePasse?.message}
    />
    <Button
      size={'large'}
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
    >
      Se connecter
    </Button>
  </form>
));

export default ConnexionForm;
