import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import screen from '../../hoc/screen';
import ConnectedLayout from '../layouts/ConnectedLayout';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { useFetch } from '../../hooks';
import { prix } from '../../utils';
import { Link } from 'react-router-dom';
import {DataGridToolbar} from "../atoms/DataGridToolbar";

export const CommandesMagasinsScreen = screen(
  (theme) => ({
    root: {},
    link: {
      color: theme.palette.secondary.main,
    },
  }),
  ({ classes }) => {
    const { data } = useFetch('/magasins');

    return (
      <ConnectedLayout title={'Liste des commandes magasins'}>
        <Typography variant={'h4'} component={'h1'}>
          Commandes par magasins
        </Typography>
        <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
          Liste des commandes effectuées par les magasins.
        </Typography>
        <Paper>
          <Box mt={3} p={2}>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Box height={900}>
                  <DataGrid
                    localeText={{toolbarExportCSV: 'Télécharger au format CSV'}}
                    components={{
                      Toolbar: DataGridToolbar,
                    }}
                    sortModel={[{ field: 'ca', sort: 'desc' }]}
                    rows={data || []}
                    columns={[
                      {
                        field: 'nom',
                        headerName: 'Magasin',
                        flex: 1,
                        renderCell: (params) =>
                          params.row.nbVentes ? (
                            <Link
                              className={classes.link}
                              to={`/commandes/magasins/${params.row.id}`}
                            >
                              {params.value}
                            </Link>
                          ) : (
                            params.value
                          ),
                      },
                      {
                        field: 'ca',
                        type: 'number',
                        headerName: 'C.A.',
                        width: 150,
                        valueFormatter: (params) => prix(params.value),
                      },
                      {
                        field: 'nbVentes',
                        type: 'number',
                        width: 110,
                        headerName: 'Ventes',
                      },
                    ]}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </ConnectedLayout>
    );
  }
);

export default CommandesMagasinsScreen;
