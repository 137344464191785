import { useEffect, useMemo, useState, createContext, useContext } from 'react';
import { base } from '../config';
import { useHistory, useLocation } from 'react-router-dom';

const AppContext = createContext({});

const localStorageAdminUserKey = 'see-admin-user';
export const getStoredUser = () => {
  return JSON.parse(localStorage.getItem(localStorageAdminUserKey));
};

export const AppContextProvider = ({ children }) => {
  const storedUser = getStoredUser();

  const [user, setUser] = useState(storedUser);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [location?.pathname, error]);

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  async function login(email, motDePasse) {
    setLoading(true);
    const res = await fetch(`${base}/admin/connexion`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, motDePasse }),
    });
    const data = await res.json();
    if (!res.ok) {
      setError(data.message);
      setLoading(false);
      setUser(null);
      return;
    }
    localStorage.setItem(localStorageAdminUserKey, JSON.stringify(data));
    history.push('/');

    setLoading(false);
    setUser(data);
  }

  /**
   * Logout app user, given an error message if argument's not null
   * @param {string} errorMessage
   */
  async function logout({ errorMessage = null }) {
    //sessionsApi.logout().then(() => setUser(undefined));
    setError(errorMessage);
    setUser(null);
    setLoading(false);
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(() => {
    return {
      user,
      loading,
      error,
      login,
      logout,
    };
  }, [user, loading, error]);

  return (
    <AppContext.Provider value={memoedValue}>{children}</AppContext.Provider>
  );
};

export const context = (Component) => {
  return (props) => {
    return (
      <AppContext.Consumer>
        {(globalState) => {
          return <Component {...globalState} {...props} />;
        }}
      </AppContext.Consumer>
    );
  };
};

export const useAuth = function () {
  return useContext(AppContext);
};
