import { object, string } from 'yup';
/*eslint no-template-curly-in-string: "off"*/
export const connexionFormSchema = object().shape({
  email: string()
    .email(
      "L'adresse email doit être dans un format correct, ex : prenom.nom@gmail.com"
    )
    .required("L'adresse email est obligatoire"),
  motDePasse: string()
    .min(6, "Le mot de passe doit être composé d'au moins ${min} caractères")
    .required('Le mot de passe est obligatoire'),
});
export const utilisateurCreateFormSchema = object().shape({
  nom: string().required('Le nom est obligatoire'),
  prenom: string().required('Le prénom est obligatoire'),
  email: string()
    .email(
      "L'adresse email doit être dans un format correct, ex : prenom.nom@gmail.com"
    )
    .required("L'adresse email est obligatoire"),
  motDePasse1: string()
    .min(6, "Le mot de passe doit être composé d'au moins ${min} caractères")
    .required('Le mot de passe est obligatoire'),
  motDePasse2: string()
    .min(6, "Le mot de passe doit être composé d'au moins ${min} caractères")
    .required('Le mot de passe est obligatoire'),
});
