import screen from "../../hoc/screen";
import {GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import React from "react";

export const DataGridToolbar = screen(
  (theme) => ({
    link: {
      color: theme.palette.secondary.main,
    },
  }),
  ({classes}) => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport className={classes.link} />
      </GridToolbarContainer>
    );
  }
);
