import React from 'react';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';

import { Switch, Route, Redirect } from 'react-router-dom';

import {
  ConnexionScreen,
  DeconnexionScreen,
  AccueilScreen,
  PrivateRoute,
  CommandesMagasinsScreen,
  CommandeMagasinScreen,
  CommandesFournisseursScreen,
  CommandeFournisseurScreen,
  TopVentesFamillesScreen,
  TopVentesRayonsScreen,
} from './';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#f4dc00',
    },
    secondary: {
      main: '#51413f',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <div className="app-wrapper">
        <CssBaseline />
        <Switch>
          <Route path="/connexion" component={ConnexionScreen} />
          <PrivateRoute path="/accueil" component={AccueilScreen} />
          <Route path="/deconnexion" component={DeconnexionScreen} />
          <PrivateRoute
            exact
            path="/"
            render={() => <Redirect to="/accueil" />}
          />

          <PrivateRoute
            exact
            path="/commandes/magasins"
            component={CommandesMagasinsScreen}
          />
          <PrivateRoute
            exact
            path="/commandes/magasins/:id"
            component={CommandeMagasinScreen}
          />
          <PrivateRoute
            exact
            path="/commandes/fournisseurs"
            component={CommandesFournisseursScreen}
          />
          <PrivateRoute
            exact
            path="/commandes/fournisseurs/:id"
            component={CommandeFournisseurScreen}
          />
          <PrivateRoute
            exact
            path="/familles/top-ventes"
            component={TopVentesFamillesScreen}
          />
          <PrivateRoute
            exact
            path="/rayons/top-ventes"
            component={TopVentesRayonsScreen}
          />
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default App;
